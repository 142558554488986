import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../../components/footer/Footer';
import Navbar from '../../../components/navbar/Navbar';
// import PayStackPop from '@paystack/inline-js';
import './local.css';

// const PayPalButton = window.paypal.Buttons.driver('react', { React, ReactDOM });
const Local = () => {
  // For the display for the PayStack
  // const [show, setshow] = useState(true);

  //for the payStack payment
  // const [email, setemail] = useState('');
  // const [amount, setamount] = useState('');
  // const [first, setfirst] = useState('');
  // const [last, setlast] = useState('');

  // const makeid = (length) => {
  //   let result = '';
  //   const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  //   const charactersLength = characters.length;
  //   let counter = 0;
  //   while (counter < length) {
  //     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  //     counter += 1;
  //   }
  //   return result;
  // }

  // const paystack = (e) => {
  //   e.preventDefault();

  //   const payme = new PayStackPop();
  //   payme.newTransaction({
  //     key: 'pk_live_439d0945863071775784e3267f50754fcd8be337',
  //     amount: amount * 100,
  //     email,
  //     first,
  //     last,
  //     onSuccess(transaction) {
  //       let message = `Payment Complete! Reference ${transaction.reference}`;
  //       alert(message);
  //       setemail('');
  //       setamount('');
  //       setfirst('');
  //       setlast('');
  //     },
  //     onCancel() {
  //       alert('You have cancelled the transaction');
  //     },
  //   });
  // };
  // const paystack2 = (e) => {
  //   e.preventDefault();

  //   const payme = new PayStackPop();
  //   payme.newTransaction({
  //     key: 'pk_test_39fa63e9fe4d9ab4ee92e510a2b85a30d7c45a65',
  //     amount: amount * 100,
  //     email,
  //     first,
  //     last,
  //     onSuccess(transaction) {
  //       let message = `Payment Complete! Reference ${transaction.reference}`;
  //       alert(message);
  //       setemail('');
  //       setamount('');
  //       setfirst('');
  //       setlast('');
  //     },
  //     onCancel() {
  //       alert('You have cancelled the transaction');
  //     },
  //   });
  // };

  // for paypal payment
  // const [price, setprice] = useState(0);
  // const createOrder = (data, actions) => {
  //   return actions.order.create({
  //     purchase_units: [
  //       {
  //         amount: {
  //           value: price,
  //         },
  //       },
  //     ],
  //   });
  // };

  // const onApprove = (data, actions) => {
  //   return actions.order.capture();
  // };
  return (
    <>
      <Navbar />
      {/* <div className="ab-sub">
        <h2>LOCAL DONATIONS</h2>
      </div> */}
      {/* For the payment body */}
      <div className="local-body">
        <div className="l-left">
          <h2>Donations</h2>
          <p>
            kindly send your donations to the following Bank account numbers;
          </p>
          <div className="bank">
            <div>
              <p>
                Bank Name : <span>Ecobank</span>
              </p>
              <p>
                Account Name :
                <span> THE CITY OF JESUS INTERNATIONAL MINISTRY</span>
              </p>
              <p>
                Account Number :<span>1190009245 (Naira)</span>
              </p>
            </div>
            <div>
              <p>
                Bank Name : <span>First Bank</span>
              </p>
              <p>
                Account Name :
                <span> THE CITY OF JESUS INTERNATIONAL MINISTRY</span>
              </p>
              <p>
                Account Number :<span> 2035291271 (Naira)</span>
              </p>
            </div>
          </div>

          <span>Purpose of donation - </span>
          <ul>
            <li>Seed sowing/Offering</li>
            <li>Tithe.</li>
          </ul>

          <p>All Donors Must send Proof of donation to the following;</p>

          <p>Email : donations@cojim.org</p>
          <p>
            Whatsapp : <span>+2347043315405</span>
          </p>
          <p>
            SMS : <span>+2347043315405</span>
          </p>

          <p className="note">
            Please state the purpose of your donation on the donation slip or the
            comment/note section of your bank transfer app.
          </p>

          <Link className="local-btn" to="/inter">
            INTERNATIONAL DONATIONS
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Local;
