import React, { useState } from "react";

const GlobalPaymentForm = () => {
    const [name, setName] = useState("");
    const [amount, setAmount] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [currency, setCurrency] = useState("USD");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [responseMessage, setResponseMessage] = useState("");

    // Generate a random transaction reference ID
    const makeid = (length) => {
        let result = "";
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    };

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent the default form submission

        setIsSubmitting(true);

        try {
            const response = await fetch("https://paygw.globalpay.com.ng/globalpay-paymentgateway/api/paymentgateway/generate-payment-link", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "apikey": "BBZY93S8467N4Q0C", // Custom header
                    "Authorization": "Bearer YOUR_TOKEN", // Example of an Authorization header
                },
                body: JSON.stringify({
                    amount: amount,
                    merchantTransactionReference: makeid(50), // Random transaction ref
                    customer: {
                        lastName: name.split(" ")[1],
                        firstName: name.split(" ")[0],
                        currency: currency,
                        phoneNumber: phone,
                        address: "string",
                        emailAddress: email
                    },
                  }),
                  
            });

            const data = await response.json(); // Assuming the response is JSON

            if (response.ok) {
                // Assuming the server responds with a payment link
                const checkoutUrl = data.data.checkoutUrl;
                if (checkoutUrl) {
                    // Redirect to the payment link
                    //   window.location.href = checkoutUrl;
                    window.open(
                        checkoutUrl,
                        '_blank' // <- This is what makes it open in a new window.
                    );
                } else {
                    setResponseMessage("Payment link not found in the response.");
                }
            } else {
                setResponseMessage("There was an error submitting the form.");
            }
        } catch (error) {
            setResponseMessage(`Error: ${error.message}`);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div>
            <form onSubmit={handleSubmit} className="py-6 px-9">
                <div className="mb-5">
                    <label htmlFor="email" className="mb-3 block text-base font-medium text-[#07074D]">
                        Fill in the required details below to continue
                    </label>

                    <div className="font-semibold">Full Name</div>
                    <div className="mb-2">
                        <input
                            name="names"
                            className="mt-1 w-full rounded-[4px] border border-[#A0ABBB] p-2"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="e.g Christopher Orji"
                        />
                    </div>

                    <div className="font-semibold">How much would you like to donate?</div>
                    <div className="mb-2">
                        <input
                            name="amount"
                            className="mt-1 w-full rounded-[4px] border border-[#A0ABBB] p-2"
                            type="text"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            placeholder="e.g 10000"
                        />
                    </div>

                    <div className="font-semibold">Enter your email address</div>
                    <div className="mb-2">
                        <input
                            name="email_address"
                            className="mt-1 w-full rounded-[4px] border border-[#A0ABBB] p-2"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="e.g cojimmedia@gmail.com"
                        />
                    </div>

                    <div className="font-semibold">Enter your phone number</div>
                    <div className="mb-2">
                        <input
                            name="phone_number"
                            className="mt-1 w-full rounded-[4px] border border-[#A0ABBB] p-2"
                            type="text"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            placeholder="e.g +2347043315405"
                        />
                    </div>

                    <div className="relative inline-flex self-center">
                        <svg
                            className="text-white bg-primary absolute top-0 right-0 m-2 pointer-events-none p-2 rounded"
                            xmlns="http://www.w3.org/2000/svg"
                            width="40px"
                            height="40px"
                            viewBox="0 0 38 22"
                            version="1.1"
                        >
                            <g id="ZahnhelferDE—Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g transform="translate(-539.000000, -199.000000)" fill="#ffffff" fillRule="nonzero">
                                    <g id="Icon-/-ArrowRight-Copy-2" transform="translate(538.000000, 183.521208)">
                                        <polygon
                                            id="Path-Copy"
                                            transform="translate(20.000000, 18.384776) rotate(135.000000) translate(-20.000000, -18.384776)"
                                            points="33 5.38477631 33 31.3847763 29 31.3847763 28.999 9.38379168 7 9.38477631 7 5.38477631"
                                        />
                                    </g>
                                </g>
                            </g>
                        </svg>
                        <select
                            name="currency"
                            className="text-2xl w-64 font-bold rounded border-2 border-pribg-primary text-gray-600 h-14 pl-5 pr-10 bg-white hover:border-gray-400 focus:outline-none appearance-none"
                            value={currency}
                            onChange={(e) => setCurrency(e.target.value)}
                        >
                            <option value="USD">USD</option>
                        </select>
                    </div>

                </div>

                <div>
                    <button
                        className="hover:shadow-form w-full rounded-md bg-primary py-3 px-8 text-center text-base font-semibold text-white outline-none"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? "Submitting..." : "Donate Now"}
                    </button>
                </div>
            </form>

            {responseMessage && <p className="text-red-500 text-center">{responseMessage}</p>}
        </div>
    );
};

export default GlobalPaymentForm;
